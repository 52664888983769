import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import BlocCta2Cols from '../components/BlocCta2Cols'
import Calendly from '../components/Calendly'

function About(props) {
  const [filter, setFilter] = useState('0')
  const [language, setLanguage] = useState('1')
  const [popupCalendly, setPopupCalendly] = useState(false)
  const [nodeLocale, setNodeLocale] = useState('')

  const getLanguage = (data) => {
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        setLanguage(0)
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language')) === '1') {
        setLanguage(1)
        setNodeLocale('fr')
      }
    }
  }, [nodeLocale])

  const data = props.data
  const {
    headline,
    excerpt,
    heroImage,
    valeur1Description,
    valeur1Titre,
    valeur2Description,
    valeur2Titre,
    valeur3Description,
    valeur3Titre,
    blocCta2Columns,
    blocHeadlineTexte2Columns,
    blocImageTexte,
    team,
    jobs,
    titleLinkOtherJobs,
    linkOtherJobs,
    imageOtherJobs
  } = data.allContentfulAbout.nodes[`${language}`]
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.allContentfulAbout.nodes[`${language}`]

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language} getLanguage={getLanguage}/>
      <Layout data={data}>
        <SEO pagePath="/about" postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-about">
          <main className="about">
            <section className="section-1">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6">
                    <div className="box-item">
                      <h1 className="headline">{headline}</h1>
                      <h2 className="description">{excerpt}</h2>
                        <ul>
                          <li className={filter === 0 ? 'checked' : '' }><AnchorLink offset="100" onClick={(e) => setFilter(0)} href="#values">{language === 0 ? 'Our values' : 'Nos valeurs'}</AnchorLink></li>
                          <li className={filter === 1 ? 'checked' : '' }><AnchorLink offset="50"  onClick={(e) => setFilter(1)} href="#team">{language === 0 ? 'Our team' : 'Notre équipe'}</AnchorLink></li>
                          <li className={filter === 2 ? 'checked' : '' }><AnchorLink href="#jobs" onClick={(e) => setFilter(2)}>{language === 0 ? 'Join us' : 'Nous rejoindre'}</AnchorLink></li>
                        </ul>
                    </div>
                  </div>
                  <div className="columns col-lg-6">
                    <div className="box-bitmap">
                      <img src={heroImage.file.url} alt={heroImage.description}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <div className="row" style={{backgroundImage: "url("+blocImageTexte.image.file.url+")" }}>
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12"/>
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="box-item">
                      <h2 className="extra-large">{blocImageTexte.headline}</h2>
                      <p>{blocImageTexte.texte.texte}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-3">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h2 className="headline">{blocHeadlineTexte2Columns.headline}</h2>
                  </div>
                  <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <p>{blocHeadlineTexte2Columns.texte.texte}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-4" id="values">
              <div className="container">
                <div className="row">
                  <h2 className="headline">{language === 0 ? 'Our values' : 'Nos valeurs'}</h2>
                </div>
                <div className="row">
                  <div className="columns col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                    <div className="box-bloc">
                      <div className="trapeze-rouge"></div>
                      <div className="box-item">
                        <h3 className="text-center">{valeur1Titre}</h3>
                        <p className="text-center">{valeur1Description.valeur1Description}</p>
                      </div>
                    </div>
                  </div>

                  <div className="columns col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                    <div className="box-bloc">
                      <div className="trapeze-rouge"></div>
                      <div className="box-item">
                        <h3 className="text-center">{valeur2Titre}</h3>
                        <p className="text-center">{valeur2Description.valeur2Description}</p>
                      </div>
                    </div>
                  </div>

                  <div className="columns col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                    <div className="box-bloc">
                      <div className="trapeze-rouge"></div>
                      <div className="box-item">
                        <h3 className="text-center">{valeur3Titre}</h3>
                        <p className="text-center">{valeur3Description.valeur3Description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-5" id="team">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-12">
                    <h2 className="headline">{language === 0 ? 'Our team' : 'Notre équipe' }</h2>
                  </div>
                </div>
                <div className="row">
                  {team.map((item) =>
                    <div className="columns col-lg-4 col-md-4 col-sm-6 col-xs-6 col-6" key={`${item.nom} - ${item.photoProfile.file.url}`}>
                      <div className="box-item">
                        <div className="box-picture" style={{ backgroundImage: "url("+item.photoProfile.file.url+")", backgroundSize: "cover"}}></div>
                        <a href={item.lienLinkedin} rel="noopener noreferrer" target="_blank"><div className="social-media"><span></span></div></a>
                        <h3>{item.nom}</h3>
                        <p>{item.description.description}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="section-6" id="jobs">
              <div className="container">
                <h2 className="headline">{language === 0 ? 'Join us' : 'Nous rejoindre' }</h2>
                <ul>
                  {jobs.map((item) =>
                    <li key={item.poste}><a href={item.lien} rel="noopener noreferrer" target="_blank">{item.poste}</a></li>
                  )}
                </ul>
                <a href={linkOtherJobs} title="" rel="noopener noreferrer" target="_blank"><p className="more">{titleLinkOtherJobs}</p></a>
                <img src={imageOtherJobs !== null ? imageOtherJobs.file.url : ''} alt="Run the office better|Faites mieux tourner le bureau"/>
              </div>
            </section>

            <BlocCta2Cols data={blocCta2Columns}/>

          <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
          </main>
        </div>
      </Layout>
      <Footer getLanguage={getLanguage}/>
    </div>
  )
}

export const query = graphql`
  query AboutQuery {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulAbout {
      nodes {
        id
        node_locale
        titre
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        headline
        excerpt
        heroImage {
          description
          file {
            url
          }
        }
        valeur1Description {
          valeur1Description
        }
        valeur1Titre
        valeur2Description {
          valeur2Description
        }
        valeur2Titre
        valeur3Description {
          valeur3Description
        }
        valeur3Titre
        blocCta2Columns {
          ctaLienGauche
          ctaLienDroite
          ctaUrlLienGauche
          ctaUrlLienDroite
          ctaTexteDroite {
            ctaTexteDroite
          }
          ctaTexteGauche {
            ctaTexteGauche
          }
        }
        blocHeadlineTexte2Columns {
          headline
          texte {
            texte
          }
        }
        blocImageTexte {
          image {
            file {
              url
            }
          }
          headline
          texte {
            texte
          }
        }
        jobs {
          lien
          poste
        }
        titleLinkOtherJobs
        linkOtherJobs
        imageOtherJobs {
          file {
            url
          }
        }
        team {
          nom
          lienLinkedin
          description {
            description
          }
          photoProfile {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default About
